@tailwind base;
@tailwind components;
@tailwind utilities;

.question-wrapper, .response-title-wrapper {
    background: linear-gradient(311.73deg, rgba(107, 178, 255, 0.1875) 9.85%, rgba(0, 122, 255, 0.125) 35.98%, rgba(107, 178, 255, 0.125) 70.39%, rgba(0, 122, 255, 0.1875) 95.04%), #0D0D0D;
    box-shadow: 0px 2px 10px rgba(0, 31, 64, 0.1);
    border-radius: 8px;
}

audio {
    display: none;
}

@font-face {
    font-family: 'Lato';
    src: url('../public/fonts/Lato/Lato-Regular.ttf') format('truetype'),
    url('../public/fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../public/fonts/Raleway/Raleway-Regular.ttf') format('truetype'),
        url('../public/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.three-lines-clamp {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

body {
    font-family: "Lato";
    overflow: hidden;
}

textarea:focus, select:focus, input:focus {
    box-shadow: none !important;
}

.ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ellipsis-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ellipsis-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.post-highlight {
	/* background-color: #a0ddf5 !important; */
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  	transform: scale(1.05);
}

.post-selected {
	background-color: #f5f5f5;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	transform: scale(1.05);
}

.rhap_button-clear.rhap_repeat-button{
    display: none;
}

.rhap_container.rhap_loop--off.rhap_play-status--playing .rhap_main-controls {
    opacity: 0.8;}

.web-responder {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.profile-top-right {
    background:
        var(--Bg-for-Questions, linear-gradient(312deg, rgba(107, 178, 255, 0.19) 9.85%, rgba(0, 122, 255, 0.13) 35.98%, rgba(107, 178, 255, 0.13) 70.39%, rgba(0, 122, 255, 0.19) 95.04%), #FFF)
}

button > div[data-testid="flowbite-toggleswitch-toggle"] {
    box-shadow: none !important;
}

.customized-audio-player .rhap_controls-section {
    display: none;
}

.customized-audio-player .rhap_main-controls-button {
    color: #1570EF;
    margin: 0;
}

.rhap_main-controls {
    z-index: 10;
}

.customized-audio-player .rhap_progress-bar-show-download {
    background-color: white;
    border: 1px solid #D0D5DD;
    border-radius: 3px;
    height: 7px;
}

.customized-audio-player .rhap_progress-indicator {
    display: none;
}

.customized-audio-player .rhap_progress-filled {
    background-color: #1570EF;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#waveform ::part(region) {
    border: 5px solid #FEC84B !important;
    border-radius: 5px !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    height: 200px !important;
}

#waveform ::part(region-handle-right) {
    border-right: 5px solid #FEC84B !important;
}

#waveform ::part(region-handle-left) {
    border-left: 5px solid #FEC84B !important;
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutToRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.modal-enter {
    animation: slideInFromRight 0.5s forwards;
}

.modal-exit {
    animation: slideOutToRight 0.5s forwards;
}

#interview-landing-page-player .rhap_progress-container, #interview-responder-landing-page-player .rhap_progress-container {
    height: 14px;
    border-radius: 35px;
    background: linear-gradient(91deg, #007AFF -20.3%, rgba(56, 23, 254, 0.00) 136.74%);
    margin-left: 0;
    padding-left: 10px;
    margin-right: 0;
    padding-right: 10px;
}
.tone-selected .rhap_progress-container {
    background: white !important;
}

#interview-landing-page-player .rhap_progress-bar-show-download, #interview-responder-landing-page-player .rhap_progress-bar-show-download {
    background-color: transparent;

}

#interview-landing-page-player .rhap_progress-filled, #interview-responder-landing-page-player .rhap_progress-filled {
    background-color: transparent;
}

#interview-landing-page-player .rhap_download-progress, #interview-responder-landing-page-player .rhap_download-progress {
    background-color: transparent;
}

#interview-landing-page-player .rhap_progress-indicator, #interview-responder-landing-page-player .rhap_progress-indicator {
    background: #007aff
}

#interview-landing-page-player .rhap_time {
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
    color: #001f40;
    padding-left: 4px;
    padding-right: 4px;
}

#interview-responder-landing-page-player .rhap_time {
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
    padding-left: 4px;
    padding-right: 4px;
}

.custom-scroll-container {
    overflow-x: scroll;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.custom-scroll-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.Toastify__toast-container {
    z-index: 9999999999;
}

.markdown-content {
    font-family: 'Lato', sans-serif; /* Font family */
}

/* Additional styles for specific Markdown elements */
.markdown-content h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.markdown-content p {
    margin: 1em 0;
}

.markdown-content pre {
    margin: 1em 0;
}

.markdown-content a {
    color: #0366d6;
    text-decoration: none;
}

.markdown-content a:hover {
    text-decoration: underline;
}

.markdown-content ul {
    list-style-type: disc;
    margin-left: 20px;
}

.markdown-content code {
    white-space: pre-wrap;
}

@media only screen and (max-width: 768px) {
    .video-recorder-full-screen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9999;
        background-color: white; /* Optional */
    }
}

