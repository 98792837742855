@keyframes spinner {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  .loading-spinner {
	width: 20px;
	height: 20px;
	border: 2px solid #f3f3f3; /* Light grey */
	border-top: 2px solid #383636; /* Blue */
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
	display: grid;
	justify-content: flex-end;
	align-items: center; 
	height: 50px;
}

.dot-pulse {
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #fff;
	color: #fff;
	box-shadow: 9999px 0 0 -5px;
	animation: dot-pulse 1.5s infinite linear;
	animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #fff;
	color: #fff;
}

.dot-pulse::before {
	box-shadow: 9984px 0 0 -5px;
	animation: dot-pulse-before 1.5s infinite linear;
	animation-delay: 0s;
}

.dot-pulse::after {
	box-shadow: 10014px 0 0 -5px;
	animation: dot-pulse-after 1.5s infinite linear;
	animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
	0% {
		box-shadow: 9984px 0 0 -5px;
	}

	30% {
		box-shadow: 9984px 0 0 2px;
	}

	60%,
	100% {
		box-shadow: 9984px 0 0 -5px;
	}
}

@keyframes dot-pulse {
	0% {
		box-shadow: 9999px 0 0 -5px;
	}

	30% {
		box-shadow: 9999px 0 0 2px;
	}

	60%,
	100% {
		box-shadow: 9999px 0 0 -5px;
	}
}

@keyframes dot-pulse-after {
	0% {
		box-shadow: 10014px 0 0 -5px;
	}

	30% {
		box-shadow: 10014px 0 0 2px;
	}

	60%,
	100% {
		box-shadow: 10014px 0 0 -5px;
	}
}